<template>
	<div class="home">
		<!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<div class="flex align-center justify-center top">
			<div class="top-title">
				望城区教育局数据可视化平台
			</div>
			<div class="top-time">
				<p style="color: #28B097; font-size: 14px;">{{ date }}</p>
				<p style="font-size: 18px;">{{ time }}</p>
			</div>
		</div>

		<div class="content flex justify-between">
			<div class="content-left" style="margin-left: 50px;">
				<div class="content-left-top">
					<div class="content-left-top-title flex align-center justify-center top">学校情况</div>
				</div>
				<div class="flex justify-center content-left-main" style="width: 100%;">
					<div class="content-left-main-block" style="width: 50%;">
						<div style="height: 50%;">
							<div class="block-title">学校分布</div>
							<div style="width:100%; height:50%" ref="schoolDistributionDOM"></div>
							<div>
								<div v-for="(item, index) of dataJson.schoolDistributionData" :key="index" class="flex align-center justify-between"
									style="width: 100%; margin-bottom: 22px;">
									<div>
										<span>去年{{item.type}}数</span>
										<span style="margin-left: 20px;">{{item.lastYear}}</span>
									</div>
									<div class="flex align-center">
										<div style="font-size: 11px;">
											环比增长
										</div>
										<div class="flex justify-center align-center" style="flex-direction: column; width: 100px;">
											{{item.QoQ}}
											<div class="semicircle" style="color: #fff;">
												<div class="semicircle-internal"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div style="height: 50%; width: 100%;">
							<div class="block-title">网络联校帮扶情况</div>
							<div style="padding: 20px; height: 90%;" v-if="dataJson.fileListHelp">
								<swiper class="banner" :options="swiperOption" ref="mySwiper">
									<swiper-slide v-for="(item, index) in dataJson.fileListHelp" :key="index">
										<div class="flex" style="width: 100%; height: 100%; flex-direction: column; justify-content: center;">
											<img :src="item" style="width: 100%;">
											<img v-if="item != dataJson.fileListHelp[Number(dataJson.fileListHelp.length) - index]" :src="dataJson.fileListHelp[Number(dataJson.fileListHelp.length) - index]" style="width: 100%;">
										</div>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
					<div class="content-left-main-block" style="margin-left: 18px;">
						<div style="height: 33.3%;">
							<div class="block-title">近五年新生数量</div>
							<div style="width:100%; height:80%" ref="numberOfNewStudentsDOM"></div>
						</div>
						<div style="height: 33.3%;">
							<div class="block-title">信息化建设情况</div>
							<div style="width:100%; height:80%" ref="informationConstructionDOM"></div>
						</div>
						<div style="height: 33.3%;">
							<div class="block-title">校园监控</div>
							<div class="flex"
								style="width:100%; height:80%; margin-top: 33px; flex-wrap: wrap; flex-direction: row-reverse;">
								<my-select @selected="useItem" :list="fruits" style="z-index: 1;"/>
								<div class="block-style" style="height: 90%; width: 100%; margin-top: 24px;">
									<video id="myPlayer" preload="auto" muted autoplay type="rtmp/flv" style="width: 100%; height: 100%;"></video>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="content-mid" style="margin: 0 22px;">
				<div class="map">
					<div style="width: 90%; height:80%; margin-left: 60px;" ref="mapDOM"></div>
				</div>
				<div style="margin-top: 16px; height: 40%;">
					<div class="content-left-top-title flex align-center justify-center"
						style="font-size: 19px; margin: auto;">区教育特色/荣誉</div>
					<div style="margin-top: 28px; height: 70%;">
						<div style="padding: 20px;" v-if="dataJson.fileList">
							<swiper class="banner" :options="swiperOption2" ref="mySwiper">
								<swiper-slide v-for="(item, index) in dataJson.fileList" :key="index">
									<img :src="item" style="width: 100%; height: 100%; object-fit: cover;">
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
			</div>

			<div class="content-right flex justify-center">
				<div style="width: 724px;">
					<div class="flex align-center content-right-top" style="font-size: 22px;">
						<div style="width: 70%; padding-top: 35px; padding-left: 20px;">教师画像</div>
						<div style="width: 30%; padding-top: 35px;">学生画像</div>
					</div>
					<div class="flex justify-center align-center" style="margin-top: 10px;">
						<div class="flex justify-between align-center" style="width: 40%; margin-right: 20px;">
							<div class="icon-a" style="width: 104px; height: 67px;">
								全区师生占比
							</div>
							<div class="icon-b" style="width: 96px; height: 96px; font-size: 19px;">
								<p class="flex align-center justify-center" style="height: 40%;">1</p>
								<p class="flex align-center justify-center" style="height: 60%;">{{ Math.round(studentData.allTotal/teacherData.allTotal) }}</p>
							</div>
						</div>
						<div class="flex justify-between align-center"
							style="width: 60%; background-color: #122A51; border-top: 1px solid #ECE7FF; height: 101px; border-bottom: 1px solid #ECE7FF; height: 101px;">
							<div class="flex justify-center flex-wrap" style="width: 33.33%; height: 100%;">
								<div class="flex justify-center align-center"
									style="width: 100%; height: 50%; font-size: 18px;">初中师生比</div>
								<div class="flex justify-center align-center" style="width: 100%; height: 50%;">
									<div class="round-style flex justify-center align-center">1</div>
									<div style="margin: 0 10px; font-size: 18px;">:</div>
									<div class="round-style flex justify-center align-center">{{ Math.round(studentData.allTotal_2/teacherData.allTotal_2) }}</div>
								</div>
							</div>
							<div class="flex justify-center flex-wrap" style="width: 33.33%; height: 100%;">
								<div class="flex justify-center align-center"
									style="width: 100%; height: 50%; font-size: 18px;">小学师生比</div>
								<div class="flex justify-center align-center" style="width: 100%; height: 50%;">
									<div class="round-style flex justify-center align-center">1</div>
									<div style="margin: 0 10px; font-size: 18px;">:</div>
									<div class="round-style flex justify-center align-center">{{ Math.round(studentData.allTotal_1/teacherData.allTotal_1) }}</div>
								</div>
							</div>
							<div class="flex justify-center flex-wrap" style="width: 33.33%; height: 100%;">
								<div class="flex justify-center align-center"
									style="width: 100%; height: 50%; font-size: 18px;">高中师生比</div>
								<div class="flex justify-center align-center" style="width: 100%; height: 50%;">
									<div class="round-style flex justify-center align-center">1</div>
									<div style="margin: 0 10px; font-size: 18px;">:</div>
									<div class="round-style flex justify-center align-center">{{ Math.round(studentData.allTotal_3/teacherData.allTotal_3) }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="flex align-center" style="margin-top: 30px;">
						<div class="flex justify-center align-center" style="width: 50%;">
							<div class="block-title" style="justify-content: initial;">
								<span style="margin-left: 48px; font-size: 12px;">老师总数</span>
								<span
									style="font-size: 34px; margin-left: 5px; position: relative; top: -10px;">{{teacherData.allTotal}}</span>
							</div>
							<div class="flex align-center justify-between" style="margin-left: 10px;">
								<div class="flex align-center">
									<div style="font-size: 11px;">
										环比增长
									</div>
									<div class="flex justify-center align-center" style="flex-direction: column; width: 75px;">
										{{ teacherData.QoQTotal }}%
										<div class="semicircle" style="color: #fff;">
											<div class="semicircle-internal"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex align-center" style="width: 50%; margin-left: 18px;">
							<div class="block-title" style="justify-content: initial;">
								<span style="margin-left: 48px; font-size: 12px;">学生总数</span>
								<span
									style="font-size: 34px; margin-left: 5px; position: relative; top: -10px;">{{studentData.allTotal}}</span>
							</div>
							<div class="flex align-center justify-between" style="margin-left: 10px;">
								<div class="flex align-center">
									<div style="font-size: 11px;">
										环比增长
									</div>
									<div class="flex justify-center align-center" style="flex-direction: column; width: 75px;">
										{{ studentData.QoQTotal }}%
										<div class="semicircle" style="color: #fff;">
											<div class="semicircle-internal"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="flex align-center" style="margin-top: 30px; height: 25%;">
						<div class="flex " style="width: 50%; height: 100%;">
							<div style="width: 33.33%; flex-direction: column;" class="flex">
								<div style="width:100%; height:50%" ref="teacherSexDOM"></div>
								<div>性别分布</div>
							</div>
							<div style="width: 33.33%; flex-direction: column;" class="flex justify-center">
								<div style="width:100%; height:50%" ref="teacherStageDOM"></div>
								<div>阶段分布</div>
							</div>
							<div style="width: 33.33%; flex-direction: column-reverse; " class="flex">
								<div>等级分布</div>
								<div style="width:100%; height:50%" ref="teacherGradeDOM"></div>
							</div>
						</div>

						<div style="width: 50%; height: 100%; margin-left: 12px;">
							<div class="block-title-d">
								课后兴趣分布
							</div>
							<div style="width:100%; height:80%" ref="interestDOM"></div>
						</div>
					</div>

					<div class="flex align-center" style="margin-top: 30px; height: 50%; margin-left: 12px;">
						<div style="width: 50%; height: 100%; ">
							<div style="width: 100%; height: 50%;">
								<div class="block-title-d">
									科目占比
								</div>
								<div style="width:100%; height:90%" ref="subjectDOM"></div>
							</div>
							<div style="width: 100%; height: 50%;">
								<div class="block-title-d">
									年龄分布
								</div>
								<div style="width:100%; height:90%" ref="ageDOM"></div>
							</div>
						</div>

						<div style="width: 50%; height: 100%; ">
							<div style="width: 100%; height: 50%;" class="flex justify-center">
								<div style="width: 50%;">
									<div class="block-title-d" style="margin: auto;">
										性别分布
									</div>
									<div style="width:100%; height:90%" ref="studentSexDOM"></div>
								</div>
								<div style="width: 50%;">
									<div class="block-title-d" style="margin: auto;">
										阶段分布
									</div>
									<div style="width:100%; height:90%" ref="studentStageDOM"></div>
								</div>
							</div>
							<div style="width: 100%; height: 50%;">
								<div class="block-title">学生今日考勤</div>
								<div style="width:100%; height:90%" ref="attendanceDOM"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	// @ is an alias to /src
	import mySelect from '@/components/my-select.vue'
	import flvjs from 'flv.js/dist/flv.min.js'
	import axios from "axios";
	import { swiper, swiperSlide } from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	
	export default {
		name: 'HomeView',
		components: {
			mySelect,
			swiper,
			swiperSlide
		},
		data() {
			return {
				apiUrl: 'https://zqxy.zqgkkj.com',
				// apiUrl: 'https://campus.test.zqgkkj.com',
				// apiUrl: 'http://192.168.0.100:10010/',
				dataJson: {},
				studentData: {},
				teacherData: {},
				geo: null,
				series: null,
				tooltip: null,
				myChart: null,
				currentIdx: 0,
				date: '',
				time: '',
				timer: null,
				fruits: [],
				monitorIndex: 0,
				swiperOption: {
					autoplay: {
						delay: 1,
					},
					speed: 8000,
					loop: true,
					direction: "vertical",
				},
				swiperOption2: {
					autoplay: {
						delay: 3000,
					},
					speed: 400,
					loop: true
				},
				regionId: ''
			}
		},
		mounted() {
			setTimeout(() =>{
				location.reload();
			},300000)
			this.regionId = this.getQuery('regionId')
			this.timer = setInterval(() => {
				this.getTime();
			}, 1000)
			this.monitorIndex = localStorage.getItem("monitorIndex") || 0;
			this.initData();
		},
		methods: {
			getQuery(key) {
				// 获取所有参数
				var query = window.location.search.substring(1);
				var hash = window.location.hash.substring(1);
				// 如果锚点后面有参数，把锚点后面的参数加入到search参数中
				if(hash.indexOf("?") > -1){
					query += "&" + hash.split("?")[1];
				}
				var key_values = query.split("&");
				var params = {};
				// 遍历参数并存入params对象
				key_values.map(function (key_val){
					var key_val_arr = key_val.split("=");
					params[key_val_arr[0]] = key_val_arr[1];
				});
				// 如果找到了key对应的参数，返回对应值
				if(typeof params[key]!="undefined"){
					return params[key];
				}
				// 如果没找到，返回空字符串
				return "";
			},
			initData() {
				axios.get(`${this.apiUrl}/api/complex/dataBigScreen/selectOne?areaId=${this.regionId}`).then(res => {
					this.dataJson = res.data.data.dataJson;
					this.fruits = [];
					this.dataJson.basicInformationData.forEach((item, index) =>{
						let json = {
							label: item.data[0].data,
							value: item.data[7].data,
							key: index
						}
						this.fruits.push(json)
					})
					this.initMonitor();
					this.initPersonnelData();
					this.initSchoolDistribution();
					this.initNumberOfNewStudents();
					this.initInformationConstruction();
					this.initMapData();
					this.initTeacherSexData();
					this.initTeacherStageData();
					this.initTeacherGradeData();
					this.initInterestData();
					this.initSubjectData();
					this.initAgeData();
					this.initStudentSexData();
					this.initStudentStageData();
					this.getAttendanceData();
				}).catch(error => {
					// 请求失败处理
					console.log(error);
				});
			},
			initMonitor() {
				let videoElement = document.getElementById('myPlayer');
				if (flvjs.isSupported()) {
					let flvPlayer = flvjs.createPlayer({
						type: 'flv',					//媒体类型
						url: `https://datashow2.zqgkkj.com/video2?url=${this.fruits[this.monitorIndex].value}`,//flv格式媒体URL
						isLive: true,					//数据源是否为直播流
						hasAudio: false,				//数据源是否包含有音频
						hasVideo: true,					//数据源是否包含有视频
						enableStashBuffer: false		//是否启用缓存区
					},{
						enableWorker: false, 			//不启用分离线程
						enableStashBuffer: false, 		//关闭IO隐藏缓冲区
						autoCleanupSourceBuffer: true 	//自动清除缓存
					});
					flvPlayer.attachMediaElement(videoElement);	//将播放实例注册到节点
					flvPlayer.load(); 					//加载数据流
					flvPlayer.play();					//播放数据流
				}
			},
			initPersonnelData() {
				let teacherTotal = 0;
				let teacherTotal_1 = 0;
				let teacherTotal_2 = 0;
				let teacherTotal_3 = 0;
				let teacherTotalLast = 0;
				let studentTotal = 0;
				let studentTotal_1 = 0;
				let studentTotal_2 = 0;
				let studentTotal_3 = 0;
				let studentTotalLast = 0;
				for (let item of this.dataJson.teacherData.total) {
					teacherTotal += Number(item.thisYear)
					teacherTotalLast += Number(item.lastYear)
					if (item.type == '小学') {
						teacherTotal_1 = Number(item.thisYear)
					}
					if (item.type == '初中') {
						teacherTotal_2 = Number(item.thisYear)
					}
					if (item.type == '高中') {
						teacherTotal_3 = Number(item.thisYear)
					}
				}
				for (let item of this.dataJson.studentData.total) {
					studentTotal += Number(item.thisYear)
					studentTotalLast += Number(item.lastYear)
					if (item.type == '小学') {
						studentTotal_1 = Number(item.thisYear)
					}
					if (item.type == '初中') {
						studentTotal_2 = Number(item.thisYear)
					}
					if (item.type == '高中') {
						studentTotal_3 = Number(item.thisYear)
					}
				}
				this.teacherData = {
					...this.dataJson.teacherData,
					allTotal: teacherTotal,
					allTotal_1: teacherTotal_1,
					allTotal_2: teacherTotal_2,
					allTotal_3: teacherTotal_3,
					QoQTotal: ((teacherTotal - teacherTotalLast) / teacherTotalLast * 100).toFixed(2)
				}
				this.studentData = {
					...this.dataJson.studentData,
					allTotal: studentTotal,
					allTotal_1: studentTotal_1,
					allTotal_2: studentTotal_2,
					allTotal_3: studentTotal_3,
					QoQTotal: ((studentTotal - studentTotalLast) / studentTotalLast * 100).toFixed(2)
				}
			},
			getAttendanceData() {
				axios.get(`${this.apiUrl}/api/school/schoolService/dataBigScreen?regionId=${this.regionId}`).then(res => {
					let yAxisData = [];
					let seriesData = [
						{
							name: '应到学生',
							type: 'bar',
							data: []
						},
						{
							name: '实到学生',
							type: 'bar',
							data: []
						},
						{
							name: '缺勤数',
							type: 'bar',
							data: []
						}
					];
					let flag = 0;
					
					setInterval(() =>{
						yAxisData = [];
						seriesData = [
							{
								name: '应到学生',
								type: 'bar',
								data: []
							},
							{
								name: '实到学生',
								type: 'bar',
								data: []
							},
							{
								name: '缺勤数',
								type: 'bar',
								data: []
							}
						];
						try{
							res.data.data.forEach((item, index) =>{
								if (index - flag >= 0) {
									if (index - flag < 5) {
										yAxisData.push(item.schoolName);
										seriesData[0].data.push(item.totalStudent)
										seriesData[1].data.push(item.countStudent)
										seriesData[2].data.push(item.noCountStudent)
										if (index == res.data.data.length - 1) {
											flag = 0;
											throw new Error("EndIterative");
										}
									} else {
										flag = index;
										throw new Error("EndIterative");
									}
								}
							})
						}catch(e){
							if(e.message != "EndIterative") throw e;
						}
						this.initAttendanceData(yAxisData, seriesData)
					},10000)
					
				}).catch(error => {
					// 请求失败处理
					console.log(error);
				});
			},
			initAttendanceData(yAxisData, seriesData) {
				let myChart = echarts.init(this.$refs["attendanceDOM"]);
				let option = {
					color: ['#2a8df2', '#2cffbf', '#6185ff'],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						top: '5%',
						left: 'center',
						textStyle: {
							color: '#fff'
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						boundaryGap: [0, 0.01],
						splitLine: {
							show: false
						}
					},
					yAxis: {
						type: 'category',
						data: yAxisData
					},
					series: seriesData
				};
				myChart.setOption(option);
			},
			initStudentSexData() {
				let myChart = echarts.init(this.$refs["studentSexDOM"]);
				let option = {
					tooltip: {
						trigger: 'item'
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF'],
					series: [{
						name: '性别分布',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#000',
							borderWidth: 2
						},
						label: {
							show: true,
							formatter(param) {
								// correct the percentage
								return param.name;
							},
							position: 'inside',
							color: '#fff'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 20,
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: this.dataJson.studentData.sex.boy || 0,
								name: '男'
							},
							{
								value: this.dataJson.studentData.sex.girl || 0,
								name: '女'
							}
						]
					}]
				};
				myChart.setOption(option);
			},
			initStudentStageData() {
				let myChart = echarts.init(this.$refs["studentStageDOM"]);
				let option = {
					tooltip: {
						trigger: 'item'
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF'],
					series: [{
						name: '阶段分布',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#000',
							borderWidth: 2
						},
						label: {
							show: true,
							formatter(param) {
								// correct the percentage
								return param.name;
							},
							position: 'inside',
							color: '#fff'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 20,
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: this.studentData.allTotal_1,
								name: '小学'
							},
							{
								value: this.studentData.allTotal_2,
								name: '初中'
							},
							{
								value: this.studentData.allTotal_3,
								name: '高中'
							}
						]
					}]
				};
				myChart.setOption(option);
			},
			initAgeData() {
				let myChart = echarts.init(this.$refs["ageDOM"]);
				
				let ageData = [];
				let ageTotal = 0;
				for (let item of this.dataJson.teacherData.age) {
					ageTotal += Number(item.data);
				}
				for (let item of this.dataJson.teacherData.age) {
					let json = {
						value: Math.round(Number(item.data)/Number(ageTotal) * 100),
						name: item.type
					}
					ageData.push(json);
				}
				
				let option = {
					color: ['#2276fc', '#0e59c1', '#00439f', '#2024d2'],
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c}%'
					},
					toolbox: {
						feature: {
							dataView: {
								readOnly: false
							},
							restore: {},
							saveAsImage: {}
						}
					},
					series: [{
						name: '年龄分布',
						type: 'funnel',
						left: '10%',
						top: 60,
						bottom: 60,
						width: '100%',
						min: 0,
						max: 100,
						minSize: '0%',
						maxSize: '100%',
						sort: 'descending',
						gap: 2,
						label: {
							show: true,
							position: 'inside'
						},
						labelLine: {
							length: 10,
							lineStyle: {
								width: 1,
								type: 'solid'
							}
						},
						itemStyle: {
							borderColor: '#000',
							borderWidth: 1
						},
						emphasis: {
							label: {
								fontSize: 20
							}
						},
						data: ageData
					}]
				};
				myChart.setOption(option);
			},
			initSubjectData() {
				let myChart = echarts.init(this.$refs["subjectDOM"]);
				
				let subjectData_1 = [];
				let subjectData_2 = []
				
				this.dataJson.teacherData.subject.forEach((item, index) =>{
					let json = {
						value: item.value,
						name: item.data
					};
					if (index > (this.dataJson.teacherData.subject.length/2)) {
						subjectData_1.push(json)
					} else {
						subjectData_2.push(json)
					}
				})
				
				let option = {
					color: ['#1d5858', '#3dbcbe', '#2b8ef3', '#15446c'],
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b}: {c}'
					},
					series: [{
							name: '科目占比',
							type: 'pie',
							radius: ['55%', '30%'],
							itemStyle: {
								borderRadius: 5,
								borderColor: '#000',
								borderWidth: 2
							},
							label: {
								position: 'inside',
								color: '#fff'
							},
							data: subjectData_1
						},
						{
							name: '科目占比',
							type: 'pie',
							radius: ['60%', '95%'],
							itemStyle: {
								borderRadius: 5,
								borderColor: '#000',
								borderWidth: 2
							},
							label: {
								position: 'inside',
							},
							data: subjectData_2
						}
					]
				};
				myChart.setOption(option);
			},
			initInterestData() {
				let myChart = echarts.init(this.$refs["interestDOM"]);
				
				let indicatorData = [];
				let value = []
				
				for (let item of this.dataJson.studentData.interest) {
					let json = {
						text: item.data
					}
					indicatorData.push(json)
					value.push(item.value)
				}
				
				let option = {
					color: ['#00A3FF', '#18FEFE', '#55FFC2', '#52E0A8'],
					radar: [{
						indicator: indicatorData,
						center: ['50%', '50%'],
						radius: 80,
						startAngle: 90,
						splitNumber: 4,
						shape: 'circle',
						axisName: {
							color: '#fff'
						},
						splitArea: {
							areaStyle: {
								color: ['#2ca69a', '#075d82', '#185657', '#0e3031'],
								shadowColor: 'rgba(0, 0, 0, 0.2)',
								shadowBlur: 10
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(78, 227, 158, 1.0)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(211, 253, 250, 0.8)'
							}
						}
					}],
					series: [{
						type: 'radar',
						emphasis: {
							lineStyle: {
								width: 4
							}
						},
						data: [
							{
								value: value,
								name: 'Data A',
								areaStyle: {
									color: 'rgba(14, 117, 154, 1.0)'
								}
							}
						]
					}, ]
				};
				myChart.setOption(option);
			},
			initTeacherGradeData() {
				let myChart = echarts.init(this.$refs["teacherGradeDOM"]);
				
				let levelData = [];
				for (let item of this.dataJson.teacherData.level) {
					let json = {
						value: item.value,
						name: item.data
					}
					levelData.push(json)
				}
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF', '#FFFA82'],
					series: [{
						name: '等级分布',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#000',
							borderWidth: 2
						},
						label: {
							show: true,
							formatter(param) {
								// correct the percentage
								return param.name;
							},
							position: 'inside',
							color: '#fff'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 20,
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: levelData
					}]
				};
				myChart.setOption(option);
			},
			initTeacherStageData() {
				let myChart = echarts.init(this.$refs["teacherStageDOM"]);
				let option = {
					tooltip: {
						trigger: 'item'
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF'],
					series: [{
						name: '阶段分布',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#000',
							borderWidth: 2
						},
						label: {
							show: true,
							formatter(param) {
								// correct the percentage
								return param.name;
							},
							position: 'inside',
							color: '#fff'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 20,
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: this.teacherData.allTotal_1,
								name: '小学'
							},
							{
								value: this.teacherData.allTotal_2,
								name: '初中'
							},
							{
								value: this.teacherData.allTotal_3,
								name: '高中'
							}
						]
					}]
				};
				myChart.setOption(option);
			},
			initTeacherSexData() {
				let myChart = echarts.init(this.$refs["teacherSexDOM"]);
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF'],
					series: [{
						name: '性别分布',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#000',
							borderWidth: 2
						},
						label: {
							show: true,
							formatter(param) {
								// correct the percentage
								return param.name;
							},
							position: 'inside',
							color: '#fff'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 20,
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: this.dataJson.teacherData.sex.boy || 0,
								name: '男'
							},
							{
								value: this.dataJson.teacherData.sex.girl || 0,
								name: '女'
							}
						]
					}]
				};
				myChart.setOption(option);
			},
			useItem(o) {
				localStorage.setItem('monitorIndex', o.index);
				location.reload();
			},
			initInformationConstruction() {
				let myChart = echarts.init(this.$refs["informationConstructionDOM"]);
				let xAxisData = [];
				let level_1 = [];
				let level_2 = [];
				let level_3 = [];
				
				this.dataJson.informationConstructionData.forEach((item, index) =>{
					if (index < 5) {
						xAxisData.push(item.value)
						item.data.forEach(childItem =>{
							if (childItem.type == '小学') {
								level_1.push(childItem.data)
							}
							if (childItem.type == '初中') {
								level_2.push(childItem.data)
							}
							if (childItem.type == '高中') {
								level_3.push(childItem.data)
							}
						})
					}
				})
				
				let option = {
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: xAxisData
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: false
						}
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF'],
					series: [{
							name: '小学',
							type: 'line',
							stack: 'Total',
							data: level_1,
							smooth: true
						},
						{
							name: '初中',
							type: 'line',
							stack: 'Total',
							data: level_2,
							smooth: true
						},
						{
							name: '高中',
							type: 'line',
							stack: 'Total',
							data: level_3,
							smooth: true
						}
					]
				};
				myChart.setOption(option);
			},
			initNumberOfNewStudents() {
				let myChart = echarts.init(this.$refs["numberOfNewStudentsDOM"]);
				
				let xAxisData = [];
				let level_1 = [];
				let level_2 = [];
				let level_3 = [];
				
				this.dataJson.numberOfNewStudentsData.forEach((item, index) =>{
					if (index < 5) {
						xAxisData.unshift(item.value)
						item.data.forEach(childItem =>{
							if (childItem.type == '小学') {
								level_1.unshift(childItem.data)
							}
							if (childItem.type == '初中') {
								level_2.unshift(childItem.data)
							}
							if (childItem.type == '高中') {
								level_3.unshift(childItem.data)
							}
						})
					}
				})
				
				let option = {
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: xAxisData
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: false
						}
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF'],
					series: [{
							name: '小学',
							type: 'line',
							stack: 'Total',
							data: level_1,
							smooth: true
						},
						{
							name: '初中',
							type: 'line',
							stack: 'Total',
							data: level_2,
							smooth: true
						},
						{
							name: '高中',
							type: 'line',
							stack: 'Total',
							data: level_3,
							smooth: true
						}
					]
				};
				myChart.setOption(option);
			},
			initSchoolDistribution() {
				let myChart = echarts.init(this.$refs["schoolDistributionDOM"]);
				
				let schoolDistributionData = [];
				for (let item of this.dataJson.schoolDistributionData) {
					let json = {
						value: item.thisYear,
						name: item.type
					}
					schoolDistributionData.push(json)
				}
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '5%',
						left: 'center',
						textStyle: {
							color: '#fff'
						}
					},
					color: ['#3DBCBE', '#2B8EF3', '#9CBECF'],
					series: [{
						name: '学校分布',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#000',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: 40,
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: schoolDistributionData
					}]
				};
				myChart.setOption(option);
			},
			initMapData() {
				// 初始化统计图对象
				this.myChart = echarts.init(this.$refs["mapDOM"]);
				
				let mapData = [];
				
				for (let item of this.dataJson.basicInformationData) {
					let json = {
						name: item.data[0].data,
						value: item.data[6].data.split(','),
						data1: item.data[1].data,
						data2: item.data[2].data,
						data3: item.data[3].data,
						data4: item.data[4].data,
						data5: item.data[5].data,
						data7: item.data[7].data
					}
					mapData.push(json)
				}
				
				this.geo = {
					map: 'region-map-wc',
					layoutSize: '100%',
					layoutCenter: ['50%', '50%'],
					// 区块名字
					label: {
						show: false, //是否显示path中的name
						color: 'rgba(61, 215, 255, 0.55)',
					},
					// 区块高亮
					emphasis: {
						focus: 'none',
						itemStyle: {
							areaColor: '#2080D7',
							borderColor: '#2B91B7',
							borderWidth: 2,
							opacity: 1,
							shadowColor: 'rgba(74, 188, 251, 0.4)',
							shadowBlur: 3,
							shadowOffsetX: 3,
							shadowOffsetY: 3,
						},
						label: {
							show: false, //高亮时是否显示path中的name
						},
					},
					// 全局分块样式
					itemStyle: {
						areaColor: '#1C3079',
						borderColor: '#2ab8ff',
					},
					// 自定义区块样式（覆盖全局样式）
					// regions: [
					// 	{
					// 		name: '乔口',
					// 		itemStyle: {
					// 			areaColor: 'rgba(44, 76, 187, 1)',
					// 			borderWidth: 2,
					// 			opacity: 1,
					// 			shadowColor: 'rgba(44, 76, 187, 0.4)',
					// 			shadowBlur: 3,
					// 			shadowOffsetX: 3,
					// 			shadowOffsetY: 3,
					// 		},
					// 	}
					// ],
				};

				this.series = [
					{
						type: 'scatter',
						coordinateSystem: 'geo',
						tooltip: {
							show: true,
						},
						symbol: 'pin',
						emphasis: {
							scale: true,
						},
						symbolSize: [30, 33],
						symbolOffset: [0, -5],
						zlevel: 20,
						itemStyle: {
							color: 'white', //坐标颜色
						},
						data: mapData,
					},
					{
						type: 'effectScatter',
						coordinateSystem: 'geo',
						tooltip: {
							show: false,
						},
						rippleEffect: {
							brushType: 'stroke',
							scale: 4,
						},
						showEffectOn: 'render',
						symbol: 'circle',
						symbolSize: [8, 5],
						zlevel: 1,
						itemStyle: {
							color: 'white', //坐标涟漪颜色
						},
						// 数据项
						data: mapData.map(item => item.value),
					},
				];

				this.tooltip = {
					show: true, //默认不显示
					formatter(param) {
						// correct the percentage
						return `<div style="text-align: left; line-height: 2;">
						<div>${param.data.name}</div>
						<div>占地<span style="font-size: 18px">${param.data.data1}</span>平方米</div>
						<div><span style="font-size: 18px">${param.data.data2}</span>老师 <span style="font-size: 18px">${param.data.data3}</span>学生</div>
						<div>开设<span style="font-size: 18px">${param.data.data4}</span>个班</div>
						<div style="max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">校园特色：${param.data.data5}</div>
						</div>`;
					},
					backgroundColor: 'rgba(7, 26, 55, 0.8)',
					borderColor: '#3DD7FF',
					borderWidth: 1,
					padding: [3, 5, 3, 5],
					textStyle: {
						color: 'white',
					},
				};
				
				this.$http.get(`region-wc.svg`).then(res => {
					echarts.registerMap('region-map-wc', { svg: res.data });
					// 渲染可视化图层
					this.myChart.setOption({
						// 地理坐标系
						geo: this.geo,
						// 数据集（散点、气泡）
						series: this.series,
						// 气泡提示
						tooltip: this.tooltip,
					});

					// 区块高亮轮播
					setTimeout(() => {
						// this.highlightNext();
					}, 2000);

					// // mapData 中 value 代表的坐标轴, 官方提供API监听事件来获取坐标
					// this.myChart.getZr().on('click', function(params) {
					// 	let pixelPoint = [params.offsetX, params.offsetY];
					// 	let dataPoint = this.myChart.convertFromPixel({
					// 		geoIndex: 0
					// 	}, pixelPoint);
					// 	console.log('点击坐标===', dataPoint);
					// });

					// // 点击SVG区块元素
					// this.myChart.on('click', {}, function(params) {
					// 	console.log('当前点击的区块===', params);
					// });
					// // 点击指定区块（SVG元素），geoIndex索引顺序和path的name属性必须完全匹配
					// this.myChart.on('click', {
					// 	geoIndex: 0,
					// 	name: '乔口'
					// }, function(params) {
					// 	console.log('点击指定区块===', params);
					// });

					// // series中type=scatter的坐标点击事件
					// this.myChart.on('click', 'series.scatter', function(params) {
					// 	console.log(params, '===');
					// });

					// 自适应缩放
					window.addEventListener('resize', () => {
						this.myChart?.resize();
					});
				}).catch(err => {
					console.log(err)
				})
			},
			highlightNext() {
				if (this.currentIdx++ >= 0) {
					this.currentIdx %= 15; //区块数
				}
				this.highlightRegion(this.currentIdx)
				setTimeout(this.highlightNext(), 2000);
			},
			highlightRegion(index) {
				const regionSort = [{
						geoIndex: 0,
						name: '乔口'
					},
					{
						geoIndex: 1,
						name: '茶亭'
					},
					{
						geoIndex: 2,
						name: '靖港'
					},
					{
						geoIndex: 3,
						name: '铜官'
					},
					{
						geoIndex: 4,
						name: '桥驿'
					},
					{
						geoIndex: 5,
						name: '高塘岭'
					},
					{
						geoIndex: 6,
						name: '丁字湾'
					},
					{
						geoIndex: 7,
						name: '白沙洲'
					},
					{
						geoIndex: 8,
						name: '大泽湖'
					},
					{
						geoIndex: 9,
						name: '月亮岛'
					},
					{
						geoIndex: 10,
						name: '金山桥'
					},
					{
						geoIndex: 11,
						name: '乌山'
					},
					{
						geoIndex: 12,
						name: '白箬铺'
					},
					{
						geoIndex: 13,
						name: '黄金园'
					},
					{
						geoIndex: 14,
						name: '雷锋'
					},
				]
				const {
					name
				} = regionSort[index];
				this.geo.regions = [{
					name: name,
					itemStyle: {
						areaColor: 'rgba(44, 76, 187, 1)',
						borderWidth: 2,
						opacity: 1,
						shadowColor: 'rgba(44, 76, 187, 0.4)',
						shadowBlur: 3,
						shadowOffsetX: 3,
						shadowOffsetY: 3,
					},
				}];
				let options = {
					geo: this.geo,
					series: this.series,
					tooltip: this.tooltip,
				};
				this.myChart.setOption(options);
			},
			getTime() {
				let date = new Date()
				this.date = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date
				.getDate());
				this.time = this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
					.getSeconds());
			},
			addZero(s) {
				return s < 10 ? '0' + s : s;
			}
		}
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
		background-size: 100% 100%;
	}
</style>
<style lang="scss" scoped>
	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.justify-center {
		justify-content: center
	}

	.justify-between {
		justify-content: space-between;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.block-title {
		width: 217px;
		height: 36px;
		background-image: url('../assets/title-bg.png');
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.block-title-d {
		width: 143px;
		height: 33px;
		font-size: 12px;
		background-image: url('../assets/title-bg-style-d.png');
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.block-style {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		// background:
		// 	linear-gradient(to top, #18FEFE, #18FEFE) left top no-repeat,
		// 	/*上左*/
		// 	linear-gradient(to right, #18FEFE, #18FEFE) left top no-repeat,
		// 	/*左上*/
		// 	linear-gradient(to left, #18FEFE, #18FEFE) right top no-repeat,
		// 	/*上右*/
		// 	linear-gradient(to bottom, #18FEFE, #18FEFE) right top no-repeat,
		// 	/*上右*/
		// 	linear-gradient(to left, #18FEFE, #18FEFE) left bottom no-repeat,
		// 	/*下左*/
		// 	linear-gradient(to bottom, #18FEFE, #18FEFE) left bottom no-repeat,
		// 	/*左下*/
		// 	linear-gradient(to top, #18FEFE, #18FEFE) right bottom no-repeat,
		// 	/*下右*/
		// 	linear-gradient(to left, #18FEFE, #18FEFE) right bottom no-repeat;
		// /*右下*/
		// background-size: 4px 26px, 26px 4px, 4px 26px, 26px 4px;
		// background-color: rgba(33, 33, 33, 1.0);
	}

	.semicircle {
		width: 54px;
		height: 27px;
		background-image: linear-gradient(to right, #52CFF6, #57FADE);
		border-radius: 100px 100px 0 0;
		position: relative;
		font-size: 14px;
		color: #0B1827;
		display: flex;
		justify-content: center;

		&-internal {
			width: 24px;
			height: 12px;
			background-color: #000;
			border-radius: 100px 100px 0 0;
			position: absolute;
			bottom: 0;
			margin: auto;
		}
	}

	.home {
		width: 100vw;
		height: 100vh;
		background-image: url('../assets/bg.png');
		overflow: hidden;
		color: #FFFFFF;
		font-weight: bold;

		.top {
			width: 100vw;
			height: 69px;
			background-image: url('../assets/top-bg.png');
			position: absolute;

			&-title {
				font-size: 30px;
			}

			&-time {
				position: absolute;
				right: 25px;
			}
		}

		.content {
			width: 100vw;
			height: 86%;
			padding: 89px 27px 0 27px;

			&-left {
				width: 40%;
				height: 100%;

				&-top {
					&-title {
						width: 281px;
						height: 62px;
						background-image: url('../assets/title-bg-style-a.png');
						font-size: 22px;
						position: initial;
					}
				}

				&-main {
					margin-top: 22px;
					height: 90%;

					&-block {
						flex: 1;
						height: 100%;
					}
				}
			}

			&-mid {
				width: 20%;
				height: 100%;

				.map {
					width: 100%;
					height: 60%;
					background-image: url('../assets/map-base.png');
				}
			}

			&-right {
				width: 40%;
				height: 100%;

				&-top {
					width: 724px;
					height: 91px;
					margin: auto;
					background-image: url('../assets/title-bg-style-c.png');
				}
			}
		}
	}

	.icon-a {
		background-image: url('../assets/icon-a.png');
	}

	.icon-b {
		background-image: url('../assets/icon-b.png');
	}

	.round-style {
		width: 30px;
		height: 30px;
		background-color: #6BD3FF;
		border-radius: 50%;
		border: 1px solid #fff;
		color: #000;
		font-size: 16px;
	}
	
	.banner {
		width: 100%;
		height: 100%;
	}
</style>