<template>
  <div class="a">
	<div @click="handleDrop" class="select">
		<span>{{active>-1?list[active].label:"切换学校"}}</span>
		<div class="triangle" style="margin-left: 6px;"></div>
	</div>
    <div class="z">
      <ul v-show="visible" >
        <li v-for="(item,index) in list" :class="index===active?'active':''" @click="choose(item,index)" :key="index">
          {{item.label}}
        </li>
      </ul>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "mySelect",
  props:['list'],
  data() {
    return {
      visible: false,
      active:-1
    }
  },
  methods: {
    choose(item,index){
      this.visible=false;
      if(this.active!==index){
        this.active=index;
 
        this.$emit("selected",{
          index:index,
          label:item.label,
          value:item.value
        })
      }
 
    },
    handleDrop(){
      this.visible=!this.visible
    },
    outClick(e){
      let dropRef= this.$el
      if(!dropRef.contains(e.target)&&this.visible){
        this.visible=false
      }
    }
  },
 
  mounted(){
    document.addEventListener('click',this.outClick)
	
  },
  updated() {
	this.active = localStorage.getItem("monitorIndex") || 0;
  },
  destroyed(){
    document.removeEventListener('click',this.outClick)
  }
 
}
</script>
 
<style scoped>
.select{
	width: 214px;
	height: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
	background:
			linear-gradient(to top, #306EEF, #306EEF) left top no-repeat,/*上左*/
			linear-gradient(to right, #306EEF, #306EEF) left top no-repeat,/*左上*/
			linear-gradient(to left, #306EEF, #306EEF) right top no-repeat,/*上右*/
			linear-gradient(to bottom, #306EEF, #306EEF) right top no-repeat,/*上右*/
			linear-gradient(to left, #306EEF, #306EEF) left bottom no-repeat,/*下左*/
			linear-gradient(to bottom, #306EEF, #306EEF) left bottom no-repeat,/*左下*/
			linear-gradient(to top, #306EEF, #306EEF) right bottom no-repeat,/*下右*/
			linear-gradient(to left, #306EEF, #306EEF) right bottom no-repeat;/*右下*/
	background-size: 2px 6px, 6px 2px, 2px 6px, 6px 2px;
	background-color: rgba(36,56,128,0.21);
}
.triangle {
	width: 0;
	height: 0;
	border-top: 8px solid #18FEFE;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
}
.a{
  position: relative;
  display: inline-block;
  text-align: center;
  user-select: none;
  color: #fff;
  font-size: 12px;
}
.z>ul{
  list-style: none;
  margin: 0;
   position: absolute;
  top:10px;
  padding-inline-start: 0;
  left: 0;
  /*left 不包含border*/
  width: 214px;
  box-shadow: inset 0 0 0 2px #125165;
  background-color: rgba(36,56,128,0.8);
}
 
.z>ul>li{
  padding: 10px 0;
}
.z{
  position: relative;
}
.z>ul>li:hover,div>ul>li.active{
  background-color: #125165;
  color: lightblue;
}
</style>