<template>
	<div class="home">
		<!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<div class="flex align-center justify-center top">
			<div class="top-title">
				{{schoolName}}学校数据可视化平台
			</div>
			<!-- <div class="top-time">
				<p style="color: #28B097; font-size: 14px;">{{ date }}</p>
				<p style="font-size: 18px;">{{ time }}</p>
			</div> -->
		</div>

		<div class="content flex justify-between">
			<div class="content-left" style="position: relative;">
				<div class="flex justify-center flex-wrap" style="height: 22%; width: 100%;">
					<div class="content-title title_a">
						<span style="margin-left: 76px;">师生比</span>
					</div>
					<div class="content-box box_a flex align-center justify-center" style="height: 70%; width: 100%;">
						<div style="width:90%; height:95%" ref="teacherStudentRatioDOM"></div>
					</div>
					<div style="position: absolute; right: -20px; top: -50px;">
						<img src="../assets/school/icon-a.png" style="width: 92px; height: 123px; object-fit: cover;">
					</div>
				</div>
				<div class="flex justify-center flex-wrap" style="height: 78%; width: 100%;">
					<div class="content-title title_b">
						<span style="margin-left: 76px;">老师画像</span>
					</div>
					<div class="content-box box_b flex align-center justify-center" style="height: 90%; width: 100%; flex-direction: column">
						<div style="height: 30%; width: 100%; position: relative;">
							<div class="subtitle" style="position: absolute; right: 40px; top: 50px;">
								<div v-for="(item, index) in 3" :key="index + 'a'" class="subtitle-icon"></div>
								<div style="margin: 0 10px;">性别分布</div>
								<div v-for="(item, index) in 3" :key="index + 'b'" class="subtitle-icon"></div>
							</div>
							<div style="width:90%; height:95%;" ref="teacherSexDOM"></div>
						</div>
						<div style="height: 30%; width: 100%; position: relative; padding-left: 20px;">
							<div class="subtitle" style="position: absolute; right: 40px; top: 50px;">
								<div v-for="(item, index) in 3" :key="index + 'a'" class="subtitle-icon"></div>
								<div style="margin: 0 10px;">年龄分布</div>
								<div v-for="(item, index) in 3" :key="index + 'b'" class="subtitle-icon"></div>
							</div>
							<div style="width:60%; height:95%;" ref="teacherAgeDOM"></div>
						</div>
						<div style="height: 40%; width: 100%; position: relative; padding-left: 20px;">
							<div class="subtitle" style="position: absolute; right: 40px; top: 50px;">
								<div v-for="(item, index) in 3" :key="index + 'a'" class="subtitle-icon"></div>
								<div style="margin: 0 10px;">科目占比</div>
								<div v-for="(item, index) in 3" :key="index + 'b'" class="subtitle-icon"></div>
							</div>
							<div style="width:60%; height:95%;" ref="teacherSubjectDOM"></div>
						</div>
					</div>
					<div style="position: absolute; right: 13px; bottom: 4px;">
						<img src="../assets/school/icon-b.png" style="width: 187px; height: 155px;">
					</div>
				</div>
			</div>
			
			<div class="content-mid" style="margin: 0 18px;">
				<div class="flex justify-center flex-wrap" style="height: 37%; width: 100%; position: relative;">
					<div class="content-title title_c">
						<span style="margin-left: 76px;">学校数据</span>
					</div>
					<div class="content-box box_c" style="height: 70%; width: 100%;">
						<div style="width: 100%; height: 28%;" class="flex justify-center align-center">
							<div class="flex justify-center align-center" style="width: 50%;">
								<div class="title-bg-a">
									<span style="margin-left: 14px;">学生</span>
								</div>
								<div class="number-bg" v-for="(item, index) of dataJson.stuTotalArr" :key="index">
									{{item}}
								</div>
							</div>
							<div class="flex justify-center align-center" style="width: 50%;">
								<div class="title-bg-a">
									<span style="margin-left: 14px;">教师</span>
								</div>
								<div class="number-bg flex align-center" v-for="(item, index) of dataJson.teaTotalArr" :key="index">
									{{item}}
								</div>
							</div>
						</div>
						<div style="width: 100%; height: 60%; padding-bottom: 10px; align-items: end;" class="flex justify-center">
							<div style="width: 35%; height: 100%;">
								<img :src="dataJson.file" alt="" style="width: 100%; height: 100%;">
							</div>
							<div class="wrap-scrollbar" style="width: 60%; height: 80%; font-size: 14px; font-weight: normal; margin-left: 14px; text-align: left;" id="scrollBox">
								{{dataJson.introduce}}
							</div>
						</div>
					</div>
					<div style="position: absolute; right: 10px; top: 100px;">
						<img src="../assets/school/icon-c.png" style="width: 89px; height: 120px;">
					</div>
				</div>
				<div class="flex justify-center flex-wrap" style="height: 63%; width: 100%; position: relative;">
					<div style="flex: 34;">
						<div class="content-title title_d">
							<span style="margin-left: 76px;">学生画像</span>
						</div>
						
						<div class="content-box box_d flex align-center justify-center" style="height: 90%; width: 100%; flex-direction: column">
							<div class="flex justify-center flex-wrap" style="height: 45%; width: 100%; align-content: center;">
								<div class="subtitle">
									<div v-for="(item, index) in 3" :key="index + 'a'" class="subtitle-icon"></div>
									<div style="margin: 0 10px;">性别分布</div>
									<div v-for="(item, index) in 3" :key="index + 'b'" class="subtitle-icon"></div>
								</div>
								<div style="width:80%; height:80%; margin: auto;" ref="studentSexDOM"></div>
							</div>
							<div class="flex justify-center flex-wrap" style="height: 55%; width: 100%; align-content: center;">
								<div class="subtitle">
									<div v-for="(item, index) in 3" :key="index + 'a'" class="subtitle-icon"></div>
									<div style="margin: 0 10px;">兴趣分布</div>
									<div v-for="(item, index) in 3" :key="index + 'b'" class="subtitle-icon"></div>
								</div>
								<div style="width:100%; height:80%" ref="interestDOM"></div>
							</div>
						</div>
					</div>
					<div style="flex: 66; margin-left: 20px;">
						<div class="content-title title_e">
							<span style="margin-left: 76px; position: absolute;">智慧校园使用数据</span>
						</div>
						
						<div class="content-box box_e flex align-center justify-center" style="height: 90%; width: 100%; flex-direction: column">
							<div style="width:90%; height:90%" ref="useDataDOM"></div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="content-right">
				<div class="flex justify-center flex-wrap" style="height: 37%; width: 100%; position: relative;">
					<div class="content-title title_f">
						<span style="margin-left: 76px;">校园风采</span>
					</div>
					<div class="content-box box_f flex align-center" style="height: 70%; width: 100%;">
						<div style="width: 91%; height: 80%; padding-left: 20px;" v-if="dataJson.fileListFC">
							<swiper class="banner" :options="swiperOption" ref="mySwiper">
								<swiper-slide v-for="(item, index) in dataJson.fileListFC" :key="index">
									<img :src="item" style="width: 100%; height: 100%; object-fit: cover;">
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
				
				<div class="flex justify-center flex-wrap" style="height: 63%; width: 100%; position: relative;">
					<div style="width: 100%; height: 54%;">
						<div class="content-title title_g">
							<span style="margin-left: 76px;">学生考勤</span>
						</div>
						<div class="content-box box_g flex justify-center align-center" style="height: 77%; width: 100%;">
							<div class="icon-d-style">
								<div>
									今日应到{{todayAttendanceData.totalStudent}}人
								</div>
								<div style="margin-top: 6px;">
									实到{{todayAttendanceData.countStudent}}人
								</div>
							</div>
							<div style="flex: 1; height:80%;">
								<div style="width: 90%; height:100%;" ref="attendanceDOM"></div>
							</div>
						</div>
					</div>
					<div style="width: 100%; height: 46%; position: relative;">
						<div class="content-title title_h">
							<span style="margin-left: 76px;">智能硬件</span>
						</div>
						<div class="content-box box_h" style="height: 77%; width: 100%;">
							<div style="width: 100%; height: 90%;" ref="deviceDOM"></div>
						</div>
						<div style="position: absolute; right: 30px; top: 80px;">
							<img src="../assets/school/icon-e.png" style="width: 60px; height: 60px;">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	// @ is an alias to /src
	import axios from "axios";
	import moment from "moment";
	import { swiper, swiperSlide } from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	
	export default {
		name: 'HomeView',
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				apiUrl: 'https://zqxy.zqgkkj.com',
				// apiUrl: 'https://campus.test.zqgkkj.com',
				// apiUrl: 'http://192.168.0.100:10010/',
				dataJson: {},
				date: '',
				time: '',
				timer: null,
				swiperOption: {
					autoplay: {
						delay: 3000,
					},
					speed: 400,
					loop: true
				},
				schoolId: '',
				schoolName: '',
				todayAttendanceData: {}
			}
		},
		mounted() {
			setTimeout(() =>{
				location.reload();
			},720000)
			this.schoolId = this.getQuery('schoolId')
			// this.timer = setInterval(() => {
			// 	this.getTime();
			// }, 1000)
			this.initData();
			this.updateScrollTop()
		},
		methods: {
			updateScrollTop() {
				let scrollBox = document.getElementById('scrollBox')
				let flag = 0;
				setInterval(() => {
					if (scrollBox.scrollHeight - scrollBox.scrollTop <= scrollBox.clientHeight + 1) {
						flag++;
						if (flag >= 30) {
							flag = 0;
							scrollBox.scrollTop = 0
						}
					} else {
						scrollBox.scrollTop++
					}
				}, 100)
			},
			getQuery(key) {
				// 获取所有参数
				var query = window.location.search.substring(1);
				var hash = window.location.hash.substring(1);
				// 如果锚点后面有参数，把锚点后面的参数加入到search参数中
				if(hash.indexOf("?") > -1){
					query += "&" + hash.split("?")[1];
				}
				var key_values = query.split("&");
				var params = {};
				// 遍历参数并存入params对象
				key_values.map(function (key_val){
					var key_val_arr = key_val.split("=");
					params[key_val_arr[0]] = key_val_arr[1];
				});
				// 如果找到了key对应的参数，返回对应值
				if(typeof params[key]!="undefined"){
					return params[key];
				}
				// 如果没找到，返回空字符串
				return "";
			},
			initData() {
				axios.get(`${this.apiUrl}/api/complex/dataBigScreen/selectOne?schoolId=${this.schoolId}`).then(res => {
					this.schoolName = res.data.data.schoolName;
					this.dataJson = {
						...res.data.data.dataJson,
						stuTotalArr: res.data.data.dataJson.studentData.total.split(''),
						teaTotalArr: res.data.data.dataJson.teacherData.total.split('')
					};
					
					this.initTeacherStudentRatio()
					this.initTeacherSex()
					this.initTeacherAge()
					this.initTeacherSubject()
					this.initStudentSex();
					this.initInterest()
					this.initUseData()
					this.getAttendanceData()
					this.initDevice()
				}).catch(error => {
					// 请求失败处理
					console.log(error);
				});
			},
			initDevice() {
				let myChart = echarts.init(this.$refs["deviceDOM"]);
				
				let sourceData = []
				
				for (let item of this.dataJson.device) {
					let json = { 
						product: item.type,
						'设备总数': item.total,  
						'在线数量': item.online,
						'离线数量': item.offline
					}
					sourceData.push(json)
				}
				
				let option = {
					color: ['#18fefe', '#3aacff', '#ed589d'],
					legend: {
						top: 'bottom',
						textStyle: {
							color: '#fff'
						}
					},
					tooltip: {},
					dataset: {
						dimensions: ['product', '设备总数', '在线数量', '离线数量'],
						source: sourceData
					},
					xAxis: { type: 'category' },
					yAxis: {
						splitLine: {
							show: false
						}
					},
					series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
				};
				myChart.setOption(option);
			},
			getAttendanceData() {
				axios.get(`${this.apiUrl}/api/school/schoolService/dataBigScreen?schoolId=${this.schoolId}`).then((res) => {
					this.todayAttendanceData = {
						countStudent: res.data.data[res.data.data.length - 1].countStudent,
						noCountStudent: res.data.data[res.data.data.length - 1].noCountStudent,
						totalStudent: res.data.data[res.data.data.length - 1].totalStudent
					}
					let myChart = echarts.init(this.$refs["attendanceDOM"]);
					let xAxisData = [];
					let seriesData = [
						{
							name: '出勤',
							type: 'line',
							stack: 'Total',
							areaStyle: {
								opacity: 0.3
							},
							emphasis: {
								focus: 'series'
							},
							data: []
						},
						{
							name: '缺勤',
							type: 'line',
							stack: 'Total',
							areaStyle: {
								opacity: 0.3
							},
							emphasis: {
								focus: 'series'
							},
							data: []
						}
					]
					
					for (let item of res.data.data) {
						xAxisData.push(item.date)
						seriesData[0].data.push(Number(item.countStudent))
						seriesData[1].data.push(Number(item.noCountStudent))
					}
					
					let option = {
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'cross',
								label: {
									backgroundColor: '#6a7985'
								}
							}
						},
						color: ['#0d94fd', '#05d0f0'],
						legend: {
							textStyle: {
								color: '#fff'
							},
							data: ['出勤', '缺勤']
						},
						toolbox: {
							feature: {
								saveAsImage: {}
							}
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: [
							{
								type: 'category',
								boundaryGap: false,
								data: xAxisData
							}
						],
						yAxis: [
							{
								type: 'value',
								splitLine: {
									show: false
								}
							}
						],
						series: seriesData
					};
					myChart.setOption(option);
				}).catch(error => {
					// 请求失败处理
					console.log(error);
				});
			},
			initUseData() {
				const startTime = moment().subtract(4,'days').format('YYYY-MM-DD');   // 近七日日期
				const endTime = moment().format('YYYY-MM-DD');   //当前日期
				axios.post(`${this.apiUrl}/api/datahive/schoolAnalyzeService/querySchoolDailyStatisticsV2`,{
					schoolId: this.schoolId,
					startTime: startTime,
					endTime: endTime
				}).then(res => {
					let legendData = [];
					let xAxisData = [];
					let seriesData = [];
					
					for (let item of res.data.data) {
						legendData.push(item.module)
						xAxisData.push(item.month)
					}
					
					legendData = [...new Set(legendData)]
					xAxisData = [...new Set(xAxisData)]
					
					for (let item of legendData) {
						let arr = res.data.data.filter((ref) =>{
							return item == ref.module
						}); 
						let json = {
							name: item,
							type: 'line',
							stack: 'Total',
							smooth: true,
							data: arr.map((ref) =>{
								return ref.value
							})
						}
						seriesData.push(json)
					}
					
					let myChart = echarts.init(this.$refs["useDataDOM"]);
					
					let option = {
						tooltip: {
							trigger: 'axis'
						},
						legend: {
							textStyle: {
								color: '#fff'
							},
							data: legendData
						},
						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						toolbox: {
							feature: {
								saveAsImage: {}
							}
						},
						xAxis: {
							type: 'category',
							boundaryGap: false,
							data: xAxisData
							
						},
						yAxis: {
							type: 'value',
							splitLine: {
								show: false
							}
						},
						series: seriesData
					};
					myChart.setOption(option);
					
				}).catch(error => {
					// 请求失败处理
					console.log(error);
				});
			},
			initInterest() {
				let myChart = echarts.init(this.$refs["interestDOM"]);
				
				let indicatorData = [];
				let value = []
				
				for (let item of this.dataJson.studentData.interest) {
					let json = {
						text: item.data
					}
					indicatorData.push(json)
					value.push(item.value)
				}
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					color: ['#00A3FF', '#18FEFE', '#55FFC2', '#52E0A8'],
					radar: [{
						indicator:  indicatorData,
						center: ['50%', '50%'],
						radius: 80,
						startAngle: 90,
						splitNumber: 4,
						shape: 'circle',
						axisName: {
							color: '#fff'
						},
						splitArea: {
							areaStyle: {
								color: ['#2ca69a', '#075d82', '#185657', '#0e3031'],
								shadowColor: 'rgba(0, 0, 0, 0.2)',
								shadowBlur: 10
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(78, 227, 158, 1.0)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(211, 253, 250, 0.8)'
							}
						}
					}],
					series: [{
						label: {
							show: true,
							color: '#fff',
							formatter: (params) =>{
								return params.value;
							}
						},
						type: 'radar',
						emphasis: {
							lineStyle: {
								width: 4
							}
						},
						data: [
							{
								value: value,
								name: '兴趣分布',
								areaStyle: {
									color: 'rgba(14, 117, 154, 1.0)'
								}
							}
						]
					}, ]
				};
				myChart.setOption(option);
			},
			initStudentSex() {
				let myChart = echarts.init(this.$refs["studentSexDOM"]);
				let boy = Number(this.dataJson.studentData.boy);
				let girl = Number(this.dataJson.studentData.girl);
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '80%',
						left: 'right',
						textStyle: {
							color: '#fff'
						}
					},
					series: [
						{
							name: '性别分布',
							type: 'pie',
							radius: ['40%', '70%'],
							avoidLabelOverlap: false,
							color: ['#06f8a2', '#eef8f5'],
							label: {
								show: false,
								position: 'center'
							},
							itemStyle: {
								borderRadius: 10,
								borderColor: '#000',
								borderWidth: 2
							},
							emphasis: {
								label: {
									show: true,
									fontSize: 24,
									fontWeight: 'bold'
								}
							},
							labelLine: {
								show: false
							},
							data: [
								{ value: boy, name: '男' },
								{ value: girl, name: '女' },
							]
						}
					]
				};
				myChart.setOption(option);
			},
			initTeacherSubject() {
				let myChart = echarts.init(this.$refs["teacherSubjectDOM"]);
				let subjectData = [];
				for (let item of this.dataJson.teacherData.subject) {
					let json = { value: item.value, name: item.data }
					subjectData.push(json)
				}
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					toolbox: {
						show: true,
						feature: {
							mark: { show: true },
							dataView: { show: true, readOnly: false },
							restore: { show: true },
							saveAsImage: { show: true }
						}
					},
					series: [
						{
							name: '科目占比',
							type: 'pie',
							radius: [20, 100],
							center: ['50%', '50%'],
							color: ['#165dff', '#18fefe', '#f7ba1e', '#d2b2ff', '#ff9900', '#ff483d', '#06f7a1', '#4c89ff', '#ffffff'],
							roseType: 'area',
							itemStyle: {
								borderRadius: 8
							},
							label: {
								color: '#fff'
							},
							data: subjectData
						}
					]
				};
				myChart.setOption(option);
			},
			initTeacherAge() {
				let myChart = echarts.init(this.$refs["teacherAgeDOM"]);
				let xAxisData = [];
				let ageData = [];
				let color = ['#12e79d', '#21eff0', '#f3f4f5', '#0357fb'];
				let index = 0;
				for (let item of this.dataJson.teacherData.age) {
					let json = {
						value: item.data,
						itemStyle: {
							color: color[index]
						}
					}
					xAxisData.push(item.type + '岁')
					ageData.push(json)
					if (index == 0 || index != 3) {
						index++
					} else if (index == 3) {
						index = 0
					}
				}
				
				let option = {
					xAxis: {
						type: 'category',
						data: xAxisData
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: false
						}
					},
					series: [
						{
							data: ageData,
							type: 'bar',
							color: ['#12e79d', '#21eff0', '#f3f4f5', '#0357fb'],
							label: {
								show: true,
								color: '#fff',
								position: 'top'
							},
						}
					]
				};
				myChart.setOption(option);
			},
			initTeacherSex() {
				let myChart = echarts.init(this.$refs["teacherSexDOM"]);
				let boy = Number(this.dataJson.teacherData.boy);
				let girl = Number(this.dataJson.teacherData.girl);
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '50%',
						left: 'right',
						textStyle: {
							color: '#fff'
						}
					},
					series: [
						{
							name: '性别分布',
							type: 'pie',
							radius: ['40%', '70%'],
							avoidLabelOverlap: false,
							color: ['#18fefe', '#ffffff'],
							label: {
								show: false,
								position: 'center'
							},
							itemStyle: {
								borderRadius: 10,
								borderColor: '#000',
								borderWidth: 2
							},
							emphasis: {
								label: {
									show: true,
									fontSize: 40,
									fontWeight: 'bold'
								}
							},
							labelLine: {
								show: false
							},
							data: [
								{ value: boy, name: '男' },
								{ value: girl, name: '女' },
							]
						}
					]
				};
				myChart.setOption(option);
			},
			initTeacherStudentRatio() {
				let myChart = echarts.init(this.$refs["teacherStudentRatioDOM"]);
				let studentTotal = Number(this.dataJson.studentData.total);
				let teacherTotal = Number(this.dataJson.teacherData.total);
				
				let option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '5%',
						left: 'center',
						// doesn't perfectly work with our tricks, disable it
						selectedMode: false,
						textStyle: {
							color: '#fff'
						}
					},
					series: [
						{
							name: '师生比',
							type: 'pie',
							radius: ['40%', '70%'],
							center: ['50%', '70%'],
							startAngle: 180,
							itemStyle: {
								borderRadius: 10
							},
							color: ['#60e730', '#ced5d8'],
							label: {
								show: true,
								color: '#fff',
								formatter(param) {
									// correct the percentage
									return param.name + ' (' + param.percent * 2 + '%)';
								}
							},
							data: [
								{ value: teacherTotal, name: '老师' },
								{ value: studentTotal, name: '学生' },
								{
									// make an record to fill the bottom 50%
									value: studentTotal + teacherTotal,
									itemStyle: {
										// stop the chart from rendering this piece
										color: 'none',
										decal: {
											symbol: 'none'
										}
									},
									label: {
										show: false
									}
								}
							]
						}
					]
				};
				myChart.setOption(option);
			},
			getTime() {
				let date = new Date()
				this.date = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date
				.getDate());
				this.time = this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
					.getSeconds());
			},
			addZero(s) {
				return s < 10 ? '0' + s : s;
			}
		}
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
		background-size: 100% 100%;
	}
</style>
<style lang="scss" scoped>
	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.justify-center {
		justify-content: center
	}

	.justify-between {
		justify-content: space-between;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.subtitle {
		font-size: 20px;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: initial;
		&-icon {
			width: 6px;
			height: 6px;
			background-color: #18fefe;
			transform: skewY(-45deg) rotate(45deg);
			margin: 0 2px;
		}
	}

	.title_a {
		background-image: url('../assets/school/title-bg-style-a.png');
	}
	.title_b {
		background-image: url('../assets/school/title-bg-style-b.png');
	}
	.title_c {
		background-image: url('../assets/school/title-bg-style-c.png');
	}
	.title_d {
		background-image: url('../assets/school/title-bg-style-d.png');
	}
	.title_e {
		background-image: url('../assets/school/title-bg-style-e.png');
	}
	.title_f {
		background-image: url('../assets/school/title-bg-style-f.png');
	}
	.title_g {
		background-image: url('../assets/school/title-bg-style-g.png');
	}
	.title_h {
		background-image: url('../assets/school/title-bg-style-h.png');
	}
	
	.box_a {
		background-image: url('../assets/school/box-style-a.png');
	}
	.box_b {
		background-image: url('../assets/school/box-style-b.png');
	}
	.box_c {
		background-image: url('../assets/school/box-style-c.png');
	}
	.box_d {
		background-image: url('../assets/school/box-style-d.png');
	}
	.box_e {
		background-image: url('../assets/school/box-style-e.png');
	}
	.box_f {
		background-image: url('../assets/school/box-style-f.png');
	}
	.box_g {
		background-image: url('../assets/school/box-style-g.png');
	}
	.box_h {
		background-image: url('../assets/school/box-style-h.png');
	}
	
	.title-bg-a {
		background-image: url('../assets/school/title-bg-a.png');
		width: 82px;
		height: 47px;
		font-size: 14px;
		text-align: left;
	}
	.number-bg {
		background-image: url('../assets/school/number-bg.png');
		width: 25px;
		height: 33px;
		font-size: 26px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-left: 2px;
	}
	::-webkit-scrollbar {
		display: none;
	}
	.wrap-scrollbar {
		overflow-y: scroll;
	}
	
	.banner {
		width: 100%;
		height: 100%;
	}
	.home {
		width: 100vw;
		height: 100vh;
		background-image: url('../assets/school/bg.png');
		overflow: hidden;
		color: #FFFFFF;
		font-weight: bold;

		.top {
			width: 100vw;
			height: 60px;
			padding-bottom: 15px;
			background-image: url('../assets/school/top-bg.png');
			position: absolute;

			&-title {
				font-size: 30px;
			}

			&-time {
				position: absolute;
				right: 25px;
			}
		}

		.content {
			height: 88%;
			padding: 89px 59px 0 59px;
			&-title {
				width: 182px;
				height: 59px;
				font-size: 22px;
				font-weight: bold;
				display: flex;
				align-items: center;
				margin: auto;
			}
			
			&-box {
				width: 100%;
			}
			
			&-left {
				width: 24%;
				height: 100%;
			}

			&-mid {
				width: 45%;
				height: 100%;

			}

			&-right {
				width: 31%;
				height: 100%;

				
			}
		}
	}
	.icon-d-style {
		width: 140px; 
		height: 102px;
		background-image: url('../assets/school/icon-d.png');
		margin: 0 20px;
		font-size: 18px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		font-weight: initial;
	}
</style>